<script lang="ts">
  import { onMount, tick } from "svelte";
  import { fetchEmailPasswordToken as update } from "../api";

  let {
    email = null,
    password = null,
    ontoken,
    onemail,
    onforgot,
  } = $props<{
    email: string | null | undefined;
    password: string | null | undefined;
    ontoken: (value: string) => void;
    onemail?: (value: string) => void;
    onforgot: (value: string) => void;
  }>();

  // export let email = null;
  // export let token = null;
  // export let password = null;

  // let emailInput;
  // let pwInput;

  logger("emailpasswordlogin");

  let form = $state(null);

  let submittable = $state(false);
  let submitting = $state(false);

  let error = $state(null);
  let success = $state(null);

  function checkSubmittable(form) {
    return (submittable = !!(
      form &&
      form.checkValidity &&
      form.checkValidity()
    ));
  }

  function change(e) {
    return (submittable = checkSubmittable(e.target.form || e.target));
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting) return;

    const form = e.target;

    if (!checkSubmittable(form)) return;

    submitting = true;
    success = error = null;

    var json = await update(
      form.elements["email"].value,
      form.elements["password"].value
    );

    //logger("result=", json);

    // handle success
    if (json.token) {
      ontoken(json.token);
      return;
    }

    submitting = false;

    // handle errors
    if (!json.token) {
      if (json.status === 401) error = "Sorry, this password doesn't match";
      else if (json.status === 404)
        error = "Sorry, we don't recognize this email";
      else if (json.status === 403 && json.message) error = json.message;
      else error = "Whoops, something went wrong";
      return;
    }
  }

  function onreset(e) {
    e.preventDefault();
    onforgot(email);
    //form.reset();
  }

  // run when form attached
  // $: if(form) {
  //     checkSubmittable(form);
  //     form.blur();
  // }

  // async function checkEmailPasswordAutofocus() {
  //     await tick();
  //     if(!email && !emailInput.value) emailInput.focus();
  //     else if(!pwInput.value) pwInput.focus();
  // }

  // $: if((emailInput && pwInput) || email) checkEmailPasswordAutofocus();

  onMount(async () => {
    await tick();
    submittable = checkSubmittable(form);
    //form.blur();
  });
</script>

<form
  bind:this={form}
  onblur={change}
  onchange={change}
  oninput={change}
  onsubmit={submit}
  {onreset}
>
  <fieldset>
    <!-- <input type="hidden" name="email" value="{email || ""}" /> -->
    <ul>
      <li>
        <!-- <label for="login-combo-email">Password</label> -->
        <input
          id="login-email"
          type="email"
          name="email"
          readonly
          spellcheck="false"
          required
          value={email}
          placeholder="Enter your login email"
        />
        <a href="/login">change</a>
      </li>
      <li>
        <!-- <label for="login-combo-email">Password</label> -->
        <input
          id="login-password"
          type="password"
          name="password"
          spellcheck="false"
          required
          autocomplete="current-password"
          placeholder="Enter your password"
          value={password || ""}
        />
        <button type="reset" disabled={submitting}>forgot?</button>
        <!-- <a href="/login/forgot?login_hint={escape(encodeURIComponent(email))}"
          >forgot?</a
        > -->
      </li>
    </ul>
  </fieldset>
  {#if error}
    <p class="error">{error}</p>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Logging in…" : "Log In"}</button
    >
  </footer>
</form>
