//import { api } from "./auth";

const base = "https://api.parkingboss.com/v2"

export async function fetchExchangeIDToken(idtoken) {

    var data = new FormData();
    data.append("id_token", idtoken);

    const res = await fetch(`${base}/auth/tokens?viewpoint=${new Date().toISOString()}&id_token=${idtoken}&lifetime=P7D`, {
        method: "POST",
        body: data
    });
    const json = await res.json();

    return json;

}

export async function fetchAuth(username) {


    const res = await fetch(`${base}/auth?viewpoint=${new Date().toISOString()}&username=${encodeURIComponent(username)}`);
    const json = await res.json();
    return json;

}

export async function fetchEmailPasswordToken(email, password) {

    var data = new FormData();
    data.append("email", email);
    data.append("password", password);


    const res = await fetch(`${base}/auth/tokens?viewpoint=${new Date().toISOString()}&email=${encodeURIComponent(email)}&lifetime=P7D`, {
        method: "POST",
        body: data
    });
    const json = await res.json();

    return json;

}

export async function fetchSendEmailToken(email) {
    var data = new FormData();
    data.append("email", email);


    const res = await fetch(`${base}/auth/tokens/email?viewpoint=${new Date().toISOString()}&email=${encodeURIComponent(email)}`, {
        method: "POST",
        body: data
    });
    const json = await res.json();

    return json;
}

export async function fetchUpdateNameEmailPassword(name: string, email: string, password: string, authorization: string): Promise<any> {

    var data = new FormData();
    data.append("name", name);
    data.append("email", email);
    if (password) data.append("password", password);

    return responseToJson(fetch(`${base}/users/self?viewpoint=${new Date().toISOString()}&Authorization=bearer ${authorization}`, {
        method: "PATCH",
        body: data
    }));

}

export async function responseToJson(response: Promise<Response>): Promise<any> {
    if (!response) return {};
    return Promise.resolve(response).then(response => response
        .text()
        .then(function (text) {
            if (!text)
                return {
                    status: response.status,
                };

            return Promise.resolve(text)
                .then(JSON.parse)
                .catch(function (error) {
                    return {
                        status: response.status,
                        message: text,
                    };
                });
        })
        .catch(function (error) {
            return {
                status: response.status,
            };
        }));
}