<script lang="ts">
  import AmenityBossPromo from "./AmenityBossPromo.svelte";
  import ParkingBossPromo from "./ParkingBossPromo.svelte";
  import WelcomeBossPromo from "./WelcomeBossPromo.svelte";

  const index = Math.floor(Math.random() * (2 - 0 + 1)) + 0;
</script>

{#if 0 === index}
  <ParkingBossPromo />
{:else if 1 === index}
  <AmenityBossPromo />
{:else if 2 === index}
  <WelcomeBossPromo />
{/if}
