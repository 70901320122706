<script lang="ts">
  import ChangeEmailPassword from "./ChangeEmailPassword.svelte";
  import Redirect from "./Redirect.svelte";
  import { route } from "../util/router";
  import ChangeEmailName from "./ChangeEmailName.svelte";

  let {
    auth_token = null,
    view = null,
    redirect_uri,
    format = null,
    onemail,
    oncancel,
  } = $props<{
    auth_token: string | null | undefined;
    view: string | null | undefined;
    redirect_uri: string;
    format: string | null | undefined;
    onemail: (value: string) => void;
    oncancel?: () => void;
  }>();

  // export let auth_token: string | null | undefined = null;
  // export let view: string | null | undefined = null;
  // export let redirect_uri: string;
  // export let format: string | null | undefined = null;

  let parsed = $derived(
    auth_token && JSON.parse(atob(auth_token.split(".")[1]))
  );
  let change = $derived(parsed && (false === parsed.password || !parsed.name));

  $effect(() => {
    if (change && "login" == view) {
      view = "change";
      route("/login/change");
    }
  });

  //$: parsed = auth_token && JSON.parse(atob(auth_token.split(".")[1]));

  //$: change = parsed && (false === parsed.password || !parsed.name);

  //$: if (change && "login" == view) (view = "change"), route("/login/change");

  //$: logger("parsed=", parsed);

  // make sure to destroy and saved sessions
  // onDestroy(() => {
  //   logout();
  // });
</script>

{#if null != change}
  {#if "login" == view && !change}
    <Redirect {auth_token} {redirect_uri} {format} />
  {:else if "change" == view && parsed.sso}
    <ChangeEmailName
      {auth_token}
      login_hint={parsed.email}
      email={parsed.email}
      name={parsed.name}
      {onemail}
      {oncancel}
    />
  {:else if "info" == view}
    <ChangeEmailName
      {auth_token}
      login_hint={parsed.email}
      email={parsed.email}
      name={parsed.name}
      {onemail}
      {oncancel}
    />
  {:else if "change" == view}
    <ChangeEmailPassword
      {auth_token}
      login_hint={parsed.email}
      email={parsed.email}
      name={parsed.name}
      required={parsed?.password === false}
      {onemail}
      {oncancel}
    />
  {/if}
{/if}
