<script lang="ts">
  import { onMount, tick } from "svelte";
  import { fetchSendEmailToken as update } from "../api";

  let { email = null, onemail } = $props<{
    email: string | null | undefined;
    onemail: (value: string) => void;
  }>();

  //export let email = null;

  let submittable = $state(false);
  let submitting = $state(false);

  let success = $state(null);
  let error = $state(null);
  let form = $state(null);

  logger("email link login");

  function checkSubmittable(form) {
    return (submittable = !!(
      form &&
      form.checkValidity &&
      form.checkValidity()
    ));
  }

  function change(e) {
    return (submittable = checkSubmittable(e.target.form || e.target));
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting) return;

    const form = e.target;

    if (!checkSubmittable(form)) return;

    submitting = true;
    error = success = null;

    var json = await update((email = form.elements["email"].value));

    //logger("result=", json);

    submitting = false;

    // we need to handle success & errors...
    if (json.status === 404) error = "Sorry, we don't recognize this email";
    else if (json.emails && json.emails.length) {
      success = "Great, check your inbox for a login email";
      email = json.emails[0];
    } else error = "Whoops, something went wrong";

    //form.reset();
  }

  // run when form attached
  // $: if(form) {
  //     checkSubmittable(form);
  //     form.blur();
  // }

  function onreset(e) {
    e.preventDefault();
    onemail(email);
    //route("/login?login_hint=" + encodeURIComponent(email || ""));
  }

  onMount(async () => {
    await tick();
    submittable = checkSubmittable(form);
  });
</script>

<form
  bind:this={form}
  onchange={change}
  oninput={change}
  onsubmit={submit}
  {onreset}
>
  <fieldset>
    <ul>
      <li>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-email"
          type="email"
          name="email"
          spellcheck="false"
          required
          value={email || ""}
          placeholder="Enter your login email"
        />
      </li>
    </ul>
  </fieldset>
  {#if success}
    <p class="success">{success}</p>
  {/if}
  {#if error}
    <p class="error">{error}</p>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Sending…" : "Send Reset Link"}</button
    >
    <button type="reset" disabled={submitting} onclick={onreset}>Back</button>
  </footer>
</form>
