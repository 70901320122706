<script lang="ts">
  import debounce from "lodash-es/debounce";
  import { logout } from "../auth";
  import { fetchUpdateNameEmailPassword as update } from "../api";
  import PasswordValidator from "./PasswordValidator";
  let {
    name = null,
    email = null,
    // login_hint = null,
    auth_token = null,
    required = null,
    onemail,
    oncancel,
  } = $props<{
    name: string | null | undefined;
    email: string | null | undefined;
    // login_hint: string | null | undefined;
    auth_token: string | null | undefined;
    required: boolean | null | undefined;
    onemail: (value: string) => void;
    oncancel?: () => void;
  }>();

  // export let name: string | null | undefined = null;
  // export let email: string | null | undefined = null;
  // export let login_hint: string | null | undefined = null;
  // export let auth_token: string | null | undefined = null;
  // export let required: boolean | null | undefined = null;
  // //export let password: boolean | null | undefined = null;
  // export let redirect_uri: string | null | undefined = null;

  let submittable = $state(false);
  let submitting = $state(false);

  let success = $state(null);
  let error = $state(null);
  let form = $state(null);
  let redirect_uri = $state("");
  const validator = new PasswordValidator();
  const pw = validator.result;

  let passwordValid = $derived(($pw?.score ?? -1) > 2);
  $effect(() => {
    if (passwordValid) checkSubmittable(form);
  });
  //$: if (passwordValid) checkSubmittable(form);
  //$: logger("validation", $pw);

  const scores = ["Weak", "Weak", "Fair", "Good", "Strong"];

  function checkSubmittable(form) {
    return (submittable = !!(
      form &&
      form.checkValidity &&
      form.checkValidity() &&
      passwordValid
    ));
  }

  function onchange(e) {
    return (submittable = checkSubmittable(e.target.form || e.target));
  }

  async function onsubmit(e) {
    e.preventDefault();

    if (submitting) return;

    const form = e.target;

    if (!checkSubmittable(form)) return;

    submitting = true;
    error = success = null;

    var json = await update(
      form.elements["name"].value,
      (email = form.elements["email"].value),
      form.elements["password"].value,
      auth_token
    );

    if (json.message) {
      success = null;
      submitting = false;
      error = json.message;
      return;
    }

    logout();
    //logIn(login_hint);

    // handle errors
    onemail(email);

    // location.href = `/login?login_hint=${escape(
    //   encodeURIComponent(login_hint || "")
    // )}`;

    //logger("result=", json);

    //form.reset();
  }

  const score = debounce(
    function (value: string) {
      validator.value.set(value);

      //if (!value || typeof value !== "string") return (complexity = null);

      //complexity = zxcvbn(value);
      //logger("complexity=", complexity);

      //return (complexity = scored);
    },
    150,
    {
      leading: false,
      trailing: true,
    }
  );

  function onpassword(value: string | HTMLInputElement | Event) {
    if (!value) return score(null);
    if (value instanceof Event) value = value.target as HTMLInputElement;
    if (value instanceof HTMLInputElement) value = value.value;
    if (value?.target) value = value.target as HTMLInputElement;
    if (value?.value) value = value.value as string;
    if (typeof value != "string") value = value?.toString();
    score(value);

    //return (complexity = scored);
  }
  function onreset(e: Event) {
    e.preventDefault();
    logout();
    oncancel?.();
    location.href = (e.target as HTMLButtonElement).value;
  }
</script>

<form
  bind:this={form}
  onblur={onchange}
  {onchange}
  oninput={onchange}
  {onsubmit}
  {onreset}
>
  <!-- <fieldset>
    <ul>
      
    </ul>
  </fieldset> -->
  <fieldset>
    <ul>
      <li>
        <label for="login-email">Email</label>
        <input
          id="login-email"
          type="email"
          name="email"
          spellcheck="false"
          required
          value={email || ""}
          readonly
          placeholder="Enter your login email"
          autocomplete="username"
        />
      </li>
      <li>
        <label for="login-name">Name</label>
        <input
          id="login-name"
          type="text"
          name="name"
          spellcheck="false"
          required
          value={name || ""}
          placeholder="required"
          autocomplete="name"
        />
      </li>
    </ul>
  </fieldset>
  <fieldset>
    <ul>
      <li>
        <!-- <label for="login-combo-email">Password</label> -->
        <input
          id="login-password"
          type="password"
          name="password"
          spellcheck="false"
          {required}
          autocomplete={required ? "new-password" : "off"}
          placeholder={required ? "Set your password" : "Change your password"}
          onblur={onpassword}
          onchange={onpassword}
          oninput={onpassword}
        />
        <data class="password score" value={$pw?.score ?? ""}
          >{scores[$pw?.score ?? -1] ?? ""}</data
        >
      </li>
    </ul>
  </fieldset>
  {#if $pw?.feedback?.warning || $pw?.feedback?.suggestions?.length}
    <p class="error">
      {[$pw.feedback.warning, ...$pw.feedback.suggestions]
        .filter(Boolean)
        .join(" ")}
    </p>
  {/if}
  {#if error}
    <p class="error">{error}</p>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving…" : "Save & Continue"}</button
    >
    {#if oncancel}
      <button type="reset">Cancel</button>
    {/if}
  </footer>
</form>
