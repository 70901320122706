import {
    zxcvbn,
    zxcvbnAsync,
    zxcvbnOptions,
    type ZxcvbnResult,
} from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import { matcherPwnedFactory } from "@zxcvbn-ts/matcher-pwned";

import { derived, writable } from "svelte/store";
import type { Readable, Writable } from "svelte/store";

const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
    },
};

zxcvbnOptions.setOptions(options);
const matcherPwned = matcherPwnedFactory(fetch, zxcvbnOptions);
zxcvbnOptions.addMatcher("pwned", matcherPwned);

type ResultType = ZxcvbnResult | null;

export default class PasswordValidator {
    #value: Writable<string>;
    #result: Readable<ResultType>;

    constructor() {
        this.#value = writable("");
        this.#result = derived<Readable<string>, ResultType>(
            this.#value,
            ($value, set) => {
                set(null);
                if (!$value) return;
                var result = zxcvbnAsync($value.toString());
                result.then(set);
            }
        );
    }

    get value(): Writable<string> {
        return this.#value;
    }
    get result(): Readable<ResultType> {
        return this.#result;
    }
}

// function PasswordValidator(value: Readable<string>): Readable<ZxcvbnResult> {
//   return derived(value, ($value) => zxcvbn($value));
// }
