import { writable, derived, readable } from "svelte/store";
import { params, param } from "./params";
import { fetchAuth } from "../api";
import { client } from "../auth";
import { session } from "./storage";

const comparer = !!window.Intl && !!window.Intl.Collator ? new Intl.Collator(undefined, { numeric: true, sensitivity: 'base', caseFirst: 'lower' }).compare : (a, b) => a < b ? -1 : a > b ? 1 : 0;

//export const client_id = param("client_id", true, "myuserv1-web");
export const client_id = session("client_id", client);
params.subscribe($params => {
    if ($params.client_id) client_id.set($params.client_id);
});

export const redirect_uri = session("redirect_uri", "https://my.communityboss.app/");
params.subscribe($params => {
    if ($params.redirect_uri) redirect_uri.set($params.redirect_uri);
});

export const format = session("format", "fragment");
params.subscribe($params => {
    if ($params.format) format.set($params.format);
});

export const username = derived(params, $params => $params.email || $params.username || $params.login_hint);
export const view = param("view");
export const token = writable();

export const user = derived(username, ($username, set) => {
    if (!$username) return set(null);
    set(null);
    fetchAuth($username).then(set);
});

export const idtoken = param("id_token");

