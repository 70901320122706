import { derived, writable } from "svelte/store";
import store from "store";
import { session } from "./storage";

const key = "access_token";
const TOKEN = writable<string | null | undefined>(null); //session(key, null);
const keys = ["access_token"];
// ensure only readable
export const token = derived(TOKEN, ($token) => $token);

// token.subscribe(($token) => {
//     if (!$token) store.remove(key);
//     else store.set(key, $token);
// });

export function clear() {
    TOKEN.set(null);
}

((window) => {

    evaluate(window);

    window.addEventListener("hashchange", hashchange);
    window.addEventListener("popstate", popstate);
    // don't need this since we're just using a memory store
    // window.addEventListener("beforeunload", () => {
    //     clear();
    // });

})(window || global);





function hashchange(e: HashChangeEvent) {
    logger("hashchange", e);
    evaluate((e.target as Window));
}
function popstate(e: PopStateEvent) {
    logger("popstate", e);
    evaluate((e.target as Window));
}
function evaluate(window: Window) {

    const original = location.href;
    const url = new URL(original);
    const hash = new URLSearchParams(url.hash.replace(/^#/, ""));
    for (const key of keys) {
        if (hash.has(key)) TOKEN.set(hash.get(key));
        else if (url.searchParams.has(key)) TOKEN.set(url.searchParams.get(key));
        url.searchParams.delete(key);
        hash.delete(key);
    }
    url.hash = hash.toString();
    const updated = url.toString();
    if (updated != original) {
        window.history.replaceState({}, "", updated);
    }

}