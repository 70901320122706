<script lang="ts">
  import {
    redirect_uri,
    view,
    username,
    user,
    idtoken,
    format,
  } from "./util/stores";
  import { params } from "./util/params";
  import { token } from "./auth";
  // import { token as dynamictoken } from "./util/auth";
  import { smscreen } from "./util/envstores";
  import EmailLinkLogin from "./components/EmailLinkLogin.svelte";
  import EmailResolve from "./components/EmailResolve.svelte";
  import EmailPasswordLogin from "./components/EmailPasswordLogin.svelte";
  import Promo from "./components/Promo.svelte";
  import { fetchExchangeIDToken } from "./api";

  import Authorized from "./components/Authorized.svelte";
  import { query } from "./util/router";

  //let token = $state(null);
  //let token = $derived($dynamictoken || $authtoken);
  let email = $state(null);
  let password = $state(null);
  let explicit_redirect_uri = $state<string | null | undefined>(null);
  //let redirect_uri = $state("");

  //$: if ($authtoken) token = $authtoken;

  $effect(() => {
    if ($username) email = $username;
    if ($params.redirect_uri) explicit_redirect_uri = $params.redirect_uri;

    if ($user && $user.authority) {
      window.location.href = `${$user.authority}?redirect_uri=${
        window.location.origin
      }/login&response_type=id_token&scope=openid email&client_id=${
        $user.client_id
      }&login_hint=${$user.username}&hd=${$user.domain || "*"}`;
    }

    if ($idtoken) {
      // have an id token, exchange for a real one
      handleIDToken($idtoken);
    }
  });

  async function handleIDToken(idtoken) {
    const json = await fetchExchangeIDToken(idtoken);
    if (json.token) ontoken(json.token);
    // {
    //   token = json.token;
    //   //alert(token);
    // }
  }

  function onemail(value: string) {
    //logger("onemail", value);
    query("login_hint", encodeURIComponent((email = value)), "/login");
  }

  function onpassword(value: string) {
    password = value;
  }
  function ontoken(value: string) {
    window.location.hash = `access_token=${value}`;
    //token = value;
  }

  function onforgot(value: string) {
    //logger("onforgot", value);
    query("login_hint", encodeURIComponent((email = value)), "/login/forgot");
  }

  function canceler(): () => void | null {
    if (!explicit_redirect_uri) return null;
    return () => {
      window.location.href = explicit_redirect_uri;
    };
  }

  // assume that query formats came in with properly handled decoding
  // $: if (token && $redirect_uri && isUrlApproved($redirect_uri)) {
  //   if ($format.toLowerCase() === "query") {
  //     const url = new URL($redirect_uri);
  //     url.searchParams.set("access_token", token);
  //     window.location.href = url.toString();
  //   } else {
  //     window.location.href =
  //       decodeURIComponent($redirect_uri).split("#")[0] +
  //       "#access_token=" +
  //       token;
  //     //redirect_uri.set(null);
  //   }
  // }
</script>

<header>
  <h1>Manage like&nbsp;a&nbsp;boss.</h1>
</header>
<main>
  <!-- <figure class="validating">Validating…</figure>   -->
  <!-- {#if token && $redirect_uri}
    <Authorized
      auth_token={token}
      redirect_uri={$redirect_uri}
      format={$format}
      view="login"
      {onemail}
      oncancel={canceler()}
    />
  {:else if $authtoken} -->
  {#if $token}
    <Authorized
      auth_token={$token}
      redirect_uri={$redirect_uri}
      format={$format}
      view={$view}
      {onemail}
      oncancel={canceler()}
    />
  {:else if "email" == $view}
    <EmailLinkLogin {email} {onemail} />
    <!-- <p>Good to go? <a href="/login?login_hint={escape(encodeURIComponent(email||""))}">Return to login…</a></p> -->
  {:else if $username && $user && $user.authority}
    <figure class="validating">Redirecting to login…</figure>
  {:else if $username && !$user}
    <figure class="validating">Checking {$username}…</figure>
  {:else if $idtoken}
    <figure class="validating">Logging in…</figure>
  {:else if $user && $user.provider && $user.username}
    <!-- <header>
        <h1>{$user.username}</h1>
        <p><a href="/login">change…</a></p>
    </header> -->
    <EmailPasswordLogin
      {password}
      email={$user.username}
      {ontoken}
      {onforgot}
    />
    <!-- <p>Forgot your password? <a href="/login/reset?login_hint={escape(encodeURIComponent($user.username))}">Request a reset link…</a></p> -->
  {:else}
    <EmailResolve
      {password}
      email={$username}
      error={$user && !$user.username && "Sorry, we don't recognize this email"}
      {onpassword}
      {onemail}
    />
  {/if}
  {#if !$smscreen}
    <Promo />
  {/if}
</main>
<nav>
  <ul>
    <li>
      Questions? Visit our <a href="https://help.communityboss.app/"
        >help center</a
      >
    </li>
    <li>
      Our <a
        href="https://help.communityboss.app/en/articles/3112854-terms-conditions"
        >terms of use</a
      >
      &amp;
      <a
        href="https://help.communityboss.app/en/articles/1833164-privacy-policy"
        >privacy policy</a
      >
    </li>
  </ul>
</nav>
<footer>
  <p>Copyright &copy; Luminous. All rights reserved.</p>
</footer>
