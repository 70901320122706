<script lang="ts">
  import { onMount, tick } from "svelte";

  let {
    email = null,
    //path = null,
    password = null,
    //param = "login_hint",
    error = null,
    onpassword,
    onemail,
  } = $props<{
    email: string | null | undefined;
    //path: string | null | undefined;
    password: string | null | undefined;
    //param: string;
    error: string | null | undefined;
    onemail: (value: string) => void;
    onpassword: (value: string) => void;
  }>();

  //   export let email = null;
  //   export let password = null;
  //   export let path = null;
  //   export let param = "login_hint";

  logger("emailstep");

  let submittable = $state(false);
  let submitting = $state(false);

  let success = $state(null);
  //   export let error = null;
  let form = $state(null);

  $effect(() => {
    if (error) submitting = false;
  });

  function checkSubmittable(form) {
    return (submittable = !!(
      form &&
      form.checkValidity &&
      form.checkValidity()
    ));
  }

  function change(e) {
    //logger("checking change");
    return (submittable = checkSubmittable(e.target.form || e.target));
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting) return;

    const form = e.target;

    if (!checkSubmittable(form)) return;

    submitting = true;
    error = success = null;

    //email = form.elements["email"].value;

    // this may be autofilled
    if (form.elements["password"])
      onpassword((password = form.elements["password"].value));

    onemail(form.elements["email"].value);

    //query(param, encodeURIComponent(form.elements["email"].value), path);

    //form.reset();
  }

  // run when form attached
  // $: if(form) {
  //     checkSubmittable(form);
  //     form.blur();
  // }

  // async function animation(e) {
  //     //await tick();
  //     logger("animation=",e);
  //     blur();
  //     setTimeout(() => change(e), 0);
  // }

  onMount(async () => {
    await tick();
    submittable = checkSubmittable(form);
  });
</script>

<form bind:this={form} onchange={change} oninput={change} onsubmit={submit}>
  <fieldset>
    <ul>
      <li class:error={!!error}>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-email"
          type="email"
          name="email"
          spellcheck="false"
          required
          value={email || ""}
          placeholder="Enter your login email"
          autocomplete="username"
        />
      </li>
      <li style="display:none">
        <input type="password" name="password" spellcheck="false" />
      </li>
    </ul>
  </fieldset>
  {#if success}
    <p class="success">{success}</p>
  {/if}
  {#if error}
    <p class="error">{error}</p>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Validating…" : "Continue"}</button
    >
  </footer>
</form>
