import { type Writable, writable } from "svelte/store";

export function session(key: string, initialValue: any | null | undefined): Writable<any> {
    const raw = sessionStorage.getItem(key);
    const sessionValue = typeof raw === "string" ? JSON.parse(raw) : raw;
    if (!sessionValue) sessionStorage.setItem(key, JSON.stringify(initialValue));

    const store = writable(sessionValue || initialValue);
    store.subscribe(value => {
        if (null == value) sessionStorage.removeItem(key);
        else sessionStorage.setItem(key, JSON.stringify(value));
    });
    return store;
}