<script lang="ts">
  let {
    auth_token = null,
    redirect_uri,
    format = null,
  } = $props<{
    auth_token: string | null | undefined;
    redirect_uri: string;
    format: string | null | undefined;
  }>();

  // export let auth_token: string | null | undefined = null;
  // export let redirect_uri: string;
  // export let format: string | null | undefined = null;

  //logout(); // don't logout because session will handle it
  if (auth_token) {
    if (format?.toLowerCase() === "query") {
      const url = new URL(redirect_uri);
      url.searchParams.set("access_token", auth_token);
      window.location.href = url.toString();
    } else {
      //var url = decodeURIComponent(redirect_uri).split("#")[0];
      window.location.href =
        decodeURIComponent(redirect_uri).split("#")[0] +
        "#access_token=" +
        auth_token;
      //redirect_uri.set(null);
    }
  }
</script>

<figure class="validating">Logging in…</figure>
