<!-- <script module lang="ts">
  import { session } from "../util/stores";
  import { params } from "../util/params";
  const redirect_uri = session("redirect_uri", "");
  params.subscribe(($params) => {
    if ($params.redirect_uri) redirect_uri.set($params.redirect_uri);
  });
</script> -->

<script lang="ts">
  import { fetchUpdateNameEmailPassword as update } from "../api";
  import { logout } from "../auth";

  let {
    name = null,
    email = null,
    // login_hint = null,
    auth_token = null,
    onemail,
    oncancel,
  } = $props<{
    name: string | null | undefined;
    email: string | null | undefined;
    // login_hint: string | null | undefined;
    auth_token: string | null | undefined;
    onemail: (value: string) => void;
    oncancel?: () => void;
  }>();

  // export let name: string | null | undefined = null;
  // export let email: string | null | undefined = null;
  // export let login_hint: string | null | undefined = null;
  // export let auth_token: string | null | undefined = null;

  // export let redirect_uri: string | null | undefined = null;

  let submittable = $state(false);
  let submitting = $state(false);

  //let success = $state(null);
  let error = $state(null);
  //let form = $state(null);

  function checkSubmittable(form) {
    return (submittable = !!(
      form &&
      form.checkValidity &&
      form.checkValidity()
    ));
  }

  function onchange(e) {
    return (submittable = checkSubmittable(e.target.form || e.target));
  }

  async function onsubmit(e) {
    e.preventDefault();

    if (submitting) return;

    const form = e.target;

    if (!checkSubmittable(form)) return;

    submitting = true;
    error = null;

    var json = await update(
      form.elements["name"].value,
      (email = form.elements["email"].value),
      form.elements["password"]?.value,
      auth_token
    );

    if (json.message) {
      //success = null;
      submitting = false;
      error = json.message;
      return;
    }

    logout();
    //logIn(login_hint);

    // handle errors

    onemail(email);

    // location.href = `/login?login_hint=${escape(
    //   encodeURIComponent(login_hint || "")
    // )}`;

    //logger("result=", json);

    //form.reset();
  }
  function onreset(e: Event) {
    e.preventDefault();
    logout();
    oncancel?.();
  }
</script>

<form onblur={onchange} {onchange} oninput={onchange} {onsubmit} {onreset}>
  <fieldset>
    <ul>
      <li>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-name"
          type="text"
          name="name"
          spellcheck="false"
          required
          value={name || ""}
          placeholder="Enter your name"
          autocomplete="name"
        />
      </li>
      <li>
        <!-- <label for="login-email">Email</label> -->
        <input
          id="login-email"
          type="email"
          name="email"
          spellcheck="false"
          required
          value={email || ""}
          placeholder="Enter your login email"
          autocomplete="username"
        />
      </li>
    </ul>
  </fieldset>

  {#if error}
    <p class="error">{error}</p>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving…" : "Save & Continue"}</button
    >
    {#if oncancel}
      <button type="reset">Cancel</button>
    {/if}
  </footer>
</form>
