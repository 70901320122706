import { router, end } from "./util/router";
import "./util/logging";
import App from "./App.svelte";
import "./app.scss";
import { mount } from "svelte";

const app = window.app = mount(App, { target: document.body, props: {} });

// // set page base path
// const prefixPath = "/properties/"
// const entryPathName = location.pathname;
// const prefixIndex = entryPathName.indexOf(prefixPath);
// const base = prefixIndex >= 0 ? entryPathName.substring(0, prefixIndex) : "";

// if(!!base) router.base(base);

// const bases = document.getElementsByTagName('base');
// function setBaseUri(url) {
//   if (!bases[0]) {
//     document.head.insertAdjacentHTML('afterbegin', `<base>`);
//   }
//   bases[0].href = `${base || "/"}${!base || base.endsWith("/") ? "" : "/"}${url || ""}`;
// }

// setBaseUri();

function view(viewname) {
  return function (ctx, next) {
    ctx.params.view = viewname;
    next();
  };
}
router("/login/change", view("change"), end);
router("/login/info", view("info"), end);
router("/info", view("info"), end);
router("/change", view("info"), end);
//router("/login/change", view("changeemail"), end);
router("/login/email", view("email"), end);
router("/login/reset", view("email"), end);
router("/login/forgot", view("email"), end);
router("/reset", view("email"), end);
router("/forgot", view("email"), end);
router("/email", view("email"), end);
//router("/login/password", view("emailpassword"), end);
router("/login", view("login"), end);
router("*", (ctx) =>
  router.redirect(["/login", ctx.querystring].filter((i) => !!i).join("?"))
);

router.start();
